<template>
  <b-overlay :show="isFetching">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h6>
        {{ `${$t('fields.result')} (${filteredList.length})` }}
      </h6>
    </div>
    <b-table
      :items="filteredList"
      :fields="fields"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(title)="data">
        {{ data.value || '-' }}
      </template>
      <template #cell(depositAmount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(bonusAmount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(turnTarget)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(turnType)="data">
        <!-- 0 = เทิร์นเครดิต
        1 = เทิร์นโอเวอร์
        2 = ไม่มีเทิร์น -->
        <b-badge
          v-if="`${data.value}` === '0'"
          variant="warning"
        >
          เทิร์นเครดิต
        </b-badge>
        <b-badge
          v-else-if="`${data.value}` === '1'"
          variant="info"
        >
          เทิร์นโอเวอร์
        </b-badge>
        <b-badge
          v-else-if="`${data.value}` === '2'"
          variant="dark"
        >
          ไม่มีเทิร์น
        </b-badge>
        <b-badge
          v-else
          variant="light"
        >
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(turnoverCounter)="data">
        {{ data.value?data.value:'-' }}
      </template>
      <template #cell(isClear)="data">
        <!-- true = หลุดเทิร์น (สีเขียว)
        false = ติดเทิร์น (สีแดง)-->
        <b-badge
          v-if="data.value === true"
          variant="success"
        >
          หลุดเทิร์น
        </b-badge>
        <b-badge
          v-else-if="data.value === false"
          variant="danger"
        >
          ติดเทิร์น
        </b-badge>
      </template>
      <template #empty="">
        <p class="text-center text-muted">
          {{
            $t('messages.nothing_here')
          }}
        </p>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'PromotionPlayerRedemptionHistory',
  props: {
    playerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      limit: 50,
      page: 1,
      fields: [
        '#',
        {
          key: 'createdAt',
          label: this.$t('fields.createdAt'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'title',
          label: this.$t('fields.promotion_title'),
          thStyle: {
            minWidth: '250px',
          },
        },
        {
          key: 'depositAmount',
          label: this.$t('fields.deposit_amount'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'bonusAmount',
          label: this.$t('fields.bonus'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'turnType',
          label: 'ประเภทเทริน',
          class: 'text-center',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'turnTarget',
          label: this.$t('fields.turn_required'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'turnoverCounter',
          label: 'เทรินโอเวอร์คงเหลือ',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'isClear',
          label: this.$t('fields.status'),
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'id',
          label: '-',
          width: '100',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.player.isFetchingPlayerPromotion,
    }),
    ...mapGetters(['playerPromotionLogs']),
    filteredList() {
      return this.playerPromotionLogs
    },
    getApplyStatus(status) {
      switch (status) {
        case 0:
          return 'APPLIED'
        case 1:
          return 'CLEARED'
        case 2:
          return 'REVOKED'
        case 3:
          return 'NONE'
        case 4:
          return 'LOSER'
        default:
          return 'unknown'
      }
    },
  },
  watch: {
    page(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPlayerPromotionLogs']),
    fetchData() {
      if(this.playerId){
        this.fetchPlayerPromotionLogs(this.playerId)
      }
    },
  },
}
</script>
